import React from "react";
import styled from "styled-components";

const Container = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  padding: 1.5rem 1.5rem;

  @media (max-width: 768px) {
    padding: 1.5rem 0;
  }
`;

const ContentWrapper = ({ children }) => {
  return <Container>{children}</Container>;
};

export default ContentWrapper;
